<template>
  <div class="logo-sidebar-wrap text-center">
    <planete-icon
        class="logo-sidebar"
        color="rgba(255,255,255,0.1)"
        height="32"
        width="32">planete-online</planete-icon>
    <br/>
    <span class="version-app white--text mb-0">v {{ version }}</span>
    <template v-if="envmode != 'production'">
      <v-chip class="info ml-2 text-truncate" x-small>{{ envmode }}</v-chip>
    </template>
    <br/>
    <v-btn
        x-small
        class="version-app white--text mb-2"
        text
        href="http://www.planete-online.fr/a-propos-de-nous#notre-histoire"
        target="_blank"
    >{{ copyrights }}
    </v-btn>
    <br/>
    <div class="version-app mb-4 white--text" v-if="!mini">
      {{ $t("app.menus.anyQuestion") }}<br/>
      <span class="cursor-pointer" @click="contact">hotline@planete-online.fr</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "SideMenuFooter",
  props: {
    mini: Boolean
  },
  computed: {
    version() {
      return process.env.VUE_APP_VERSION;
    },
    envmode() {
      return process.env.VUE_APP_ENV
    },
    copyrights() {
      let date = new Date().getFullYear()
      return this.mini ? (date + " ©") : ("PLANETE-ONLINE © " + date)
    },
  },
  methods: {
    contact() {
      window.location.href = "mailto:hotline@planete-online.fr";
    },
  }
}
</script>

<style scoped>
.logo-sidebar {
  margin: 8px auto;
}

.logo-sidebar-wrap {
  text-align: center;
}

.version-app {
  font-size: 0.6em;
}

.cursor-pointer {
  cursor: pointer;
}
</style>